import React from 'react'

import Layout from '../utils/Layout/Layout'
// import Header from '../components/Header/Header'
import PageHeader from '../components/PageHeader/PageHeader'
import PageContactSection from '../components/PageContactSection/PageContactSection'
import ParticipateSection from '../components/ParticipateSection/ParticipateSection'
import EmailSuscribe from '../components/EmailSuscribe/EmailSuscribe'
import SEO from '../components/seo'

const ContactPage = () => (
  <Layout>
    <SEO title="Get In Touch" />
    <PageHeader heading="Get In Touch" paragraph="" />
    <PageContactSection
      heading="Get In Touch"
      paragraph="Feel free to contact us with an questions, ideas, and feedback you may have. Our only goal is to put on an amazing family-orientated event that celebrates our robust African diaspora. Our hope is that this event will help create long-term positive impact that improves the quality of life for our downtown area and the whole city."
      email="info@afrofestmn.com"
    />
    <EmailSuscribe />
    <ParticipateSection />
  </Layout>
)

export default ContactPage
