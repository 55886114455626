import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faGithub,
//   faFacebook,
//   faTwitter,
//   faGmail,
// } from '@fortawesome/free-brands-svg-icons'
import './PageContactSection.scss'

const PageContactSection = props => (
  <section className="page-contact-section" id="page-contact-section">
    <div className="container">
      <h1>{props.heading} </h1>
      <p>{props.paragraph}</p>
      <p>{props.paragraph2}</p>
      <p>{props.paragraph3}</p>
      <div class="socialmedialinks">
        <p class="">
          Send an email to{' '}
          <strong>
            {' '}
            <a href="mailto:{props.email}">{props.email}</a>
          </strong>{' '}
          anytime!
        </p>
      </div>
    </div>
  </section>
)

export default PageContactSection
